<template>
  <div>
    <!-- MAIN div contenitore da inserire nelle pagine Risultato di ricerca (class="main results-page") e Dettaglio volume (class="main detail") -->
    <div class="main results-page">
      <!-- SEARCH -->
      <div class="search container bg-grey" style="z-index: 3" v-show="!showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <h1>Il Catalogo</h1>
            <!-- viene mostrato solo in versione smartphone -->
            <div class="search__caption__copy__searchbox">
              <div class="filter">
                <div class="filter__search">
                  <v-select class="filter__search__select" name @change="filtering()" v-model="typeResearch" :value="typeResearch" :items="filtersTypeResearch">
                    <!--<option>Tutto il Catalogo</option>
                    <option v-if="checkRules()">I miei volumi</option>
                    <option>Unlimited</option>-->
                  </v-select>
                  <v-text-field
                    class="srch filter__search__input"
                    v-on:keyup="keyPress"
                    dense
                    v-model="searchText"
                    :placeholder="placeholder"
                    @click:append="
                      page = 1
                      removenlp = false
                      materiaSelected = null
                      showResAdv = false
                      currentDateFiltering = ''
                      dateTo = null
                      dateFrom = null
                      last2Years = false
                      last5Years = false
                      research(0, 8, 'Tutto il Catalogo', true)
                    "
                    rounded
                    append-icon="search"></v-text-field>
                </div>
                <div class="filter__select">
                  <!--<v-select
                  class="slct"
                  style="padding-top: 6px; height:45px;"
                  :items="orders"
                  v-model="currentOrder"
                  @change="setOrder()"
                  rounded
                  return-object
                  item-text="name"
                  dense
                  label="Ordina"
                  ></v-select>-->
                  <v-select
                    class="srch filter__select__materia"
                    v-model="materiaSelected"
                    :items="materie"
                    multiple
                    light
                    item-text="nome_interesse"
                    dense
                    placeholder="Materia"
                    autocomplete="off"
                    return-object
                    rounded
                    :menu-props="{ maxHeight: '250', minWidth: '400' }"
                    @change="setMateria()">
                    <template v-slot:selection="{ item, index }">
                      <span style="font-size: 10pt" v-if="index == 0" class="hidden-md-and-up">{{ item.nome_interesse.substring(0, 7) }}..</span>
                      <span style="font-size: 10pt" v-if="index == 0" class="hidden-sm-and-down">{{ item.nome_interesse }}</span>
                      <span v-else-if="index == 1" class="hidden-sm-and-down">...</span>
                    </template>
                  </v-select>
                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" :menu-props="{ maxHeight: '250', closeOnClick: true }">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="srch filter__select__data"
                        v-on="on"
                        light
                        readonly
                        style="cursor: pointer !important"
                        :placeholder="'Filtra per Data ' + currentDateFiltering"
                        autocomplete="off"
                        rounded
                        offset-y
                        :append-icon="appendIconData"
                        :menu-props="{
                          maxHeight: '250',
                          closeOnClick: true,
                          closeOnContentClick: true
                        }"></v-text-field>
                    </template>

                    <v-card>
                      <v-card-text>
                        <v-checkbox color="primary" style="margin-top: 0px" v-model="last2Years" class="mx-2" label="Ultimi 2 anni"></v-checkbox>
                        <v-checkbox color="primary" style="margin-top: -10px" v-model="last5Years" class="mx-2" label="Ultimi 5 anni"></v-checkbox>

                        <div class="search__advanced__copy__item" style="margin-top: -15px">
                          <v-flex xs12 sm6 md4 class="advanced__row">
                            <label>Da</label>
                            <v-text-field label="Anno" type="number" v-model="dateFrom" :rules="[rules.min]" class="inp"></v-text-field>
                            <label style="margin-left: 20px">A</label>
                            <v-text-field label="Anno" style="width: 400px" type="number" v-model="dateTo" :rules="[rules.max]" class="inp"></v-text-field>
                          </v-flex>
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="cta orange"
                          text
                          @click="
                            showResAdv = true
                            page = 1
                            removenlp = false
                            currentDateFiltering = dateFrom ? ': ' + dateFrom + ' - ' + dateTo : ''
                            research((page - 1) * 8, 8, typeResearch)
                            menu = false
                          "
                          >Cerca</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search container bg-grey action-viewer" style="z-index: 3" v-show="showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <!-- viene mostrato solo in versione smartphone -->
            <div class="filter results__actions">
              <span class="lnk blue" style="cursor: pointer" @click="showViewer = false" title="Torna alla result list">
                <span class="mdi mdi-chevron-left"></span>
                <span class="text" style="font-weight: 700 !important">Torna alla result list</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="loading_viewer">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container v-if="showViewer" class="reset-padding">
        <Viewer :key="currentKey" :settings="settings"></Viewer>
      </v-container>
      <v-container v-show="!showViewer">
        <div class="section">
          <!-- RESULTS ACTIONS div in cui inserire le CTA torna alla ricerca e switch dei preferiti nel dettaglio volume -->

          <!-- RESULTS LIST -->
          <div class="results-list">
            <div class="results-list__items">
              <v-alert dense v-show="showSearchByAuthor" outlined icon="info">
                Risultati per autore: {{ currentAuthor }} |
                <a @click="removeNlp()">Cerca invece nel testo</a>
              </v-alert>

              <!--<v-pagination
                    style="justify-content:start;"
                    v-model="page"
                    v-if="list.length > 0"
                    :length="totalPages"
                     :total-visible="8"
              ></v-pagination>-->

              <div class="results-list__items__item clearfix" v-for="item in list" :key="item">
                <div class="element clearfix">
                  <div
                    class="col20 floatL element__image"
                    @click="
                      visite(item, 'set')
                      detail(item)
                    ">
                    <a class="element__image__action" title="Vai al dettaglio del volume">
                      <div class="grid__item__caption__corner" v-if="item._source.provvedimento.utente.demo">DEMO</div>
                      <img :src="getImage(item._source)" style="width: 217px !important; height: 306px !important; height: 100%; min-height:306px" />
                    </a>
                  </div>
                  <div class="col80 floatR element__caption">
                    <div class="element__caption__header">
                      <a
                        @click="
                          visite(item, 'set')
                          detail(item)
                        "
                        :class="[
                          {
                            'title uppercase': true,
                            'title-visited': visite(item, 'check')
                          }
                        ]"
                        style="cursor: pointer"
                        title="Vai al dettaglio del volume"
                        >{{ item._source.provvedimento.campiCalcolati.estremo }}</a
                      >
                      <p v-if="item._source.provvedimento.collana" class="element__caption__header">
                        <span>{{ item._source.provvedimento.collana.descrizione }}</span>
                      </p>
                      <a
                        @click="
                          visite(item, 'set')
                          detail(item)
                        "
                        :class="[
                          {
                            title: true,
                            'title-visited': visite(item, 'check')
                          }
                        ]"
                        style="cursor: pointer; font-size: 10pt !important; display: block"
                        title="Vai al dettaglio del volume"
                        v-if="checkConditionVolume(item)">
                        vedi un estratto del volume
                      </a>
                    </div>
                    <div class="element__caption__metadata">
                      <div class="metadata-row">
                        <span class="metadata" v-if="item._source.autori">Autori:</span>
                        <span class="metadata__value" v-if="item._source.autori && item._source.autori.length <= 10">
                          <span v-for="(autore, index) in item._source.autori" :key="autore">
                            {{ autore.nome }} {{ autore.cognome }}
                            <span v-if="item._source.autori.length > 1 && index < item._source.autori.length - 1">;</span>
                          </span>
                        </span>
                        <span class="metadata__value" v-else-if="item._source.autori">
                          <span v-for="(autore, index) in item._source.autori.slice(0, 10)" :key="autore">
                            {{ autore.nome }} {{ autore.cognome }}
                            <span v-if="item._source.autori.length > 1 && index < item._source.autori.length - 1">;</span>
                          </span>
                          <a class="metadata__lnkmore" @click="showMoreAuth(item)" v-show="!item.showMoreAuthors">Leggi di più</a>
                          <span v-show="item.showMoreAuthors" v-for="(autore, index) in item._source.autori.slice(10, 100)" :key="autore">
                            {{ autore.nome }} {{ autore.cognome }}
                            <span v-if="item._source.autori.length > 1 && index < item._source.autori.slice(10, 100).length - 1">;</span>
                          </span>
                        </span>
                      </div>

                      <div class="metadata-row">
                        <span class="metadata">Anno di edizione:</span>
                        <span class="metadata__value">{{ item._source.provvedimento.annoEd }}</span>
                      </div>
                      <div class="metadata-row">
                        <span class="metadata">Cod. Volume:</span>
                        <span class="metadata__value">{{ item._source.provvedimento.codVol }}</span>
                      </div>
                      <div class="metadata-row">
                        <span class="metadata">ISBN:</span>
                        <span class="metadata__value">{{ item._source.provvedimento.codIsbn }}</span>
                      </div>
                      <div class="metadata-row">
                        <span class="metadata">Materie:</span>
                        <span class="metadata__value">
                          <span v-for="materia in item._source.provvedimento.materie" :key="materia">
                            {{ materia.type == 'interesse' ? materia.descr : '' }}
                            <span v-if="materia.type == 'interesse' && item._source.provvedimento.materie.length > 1">;</span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <div
                      class="element__caption__add"
                      @click="goToShop(item)"
                      style="display: table; margin-bottom: 5px; min-width: 220px; cursor: pointer; line-height: 25px !important; letter-spacing: 0.6px"
                      v-if="item._source.provvedimento.utente.volume == false">
                      <span class="label" style="line-height: 14px; font-size: 8.5pt">
                        Acquista il Volume nella<br />
                        versione cartacea dallo shop GFL
                      </span>
                      <a
                        target="_blank"
                        v-show="false"
                        :href="'https://shop.giuffre.it/product.php?cod=' + item._source.provvedimento.codVol"
                        class="metadata"
                        style="color: #0095d8"
                        >Acquista sullo shop</a
                      >
                    </div>

                    <div class="element__caption__add" v-if="item.showMyVolumesBtn" style="cursor: pointer; display: table">
                      <span class="label">
                        Già presente nella sezione
                        <span class="title uppercase">I miei volumi</span>
                      </span>
                      <a class="lnk lightBlue" title="Consulta il volume che hai acquistato">
                        <span class="mdi mdi-eye-outline"></span>
                        <span class="text" @click="detail(item)">Consultalo subito</span>
                      </a>
                    </div>
                    <div class="element__caption__add" v-if="item.showUnlimitedBtn" style="margin-top: 15px; cursor: pointer">
                      <span class="label">
                        Incluso nell'abbonamento
                        <span @click="goToUnlimited" style="cursor: pointer" class="title uppercase">Unlimited</span>
                        <!--IL GIUDICATO PENALE.-->
                      </span>
                      <a
                        class="lnk lightBlue"
                        @click="
                          loadingAdd = true
                          addToUnlimited(item)
                        "
                        title="Aggiungi subito nella sezione Unlimited">
                        <span class="mdi mdi-plus-circle"></span>
                        <span class="text">Aggiungi subito</span>
                      </a>
                    </div>
                    <div class="element__caption__add" v-if="item.showUnlimitedBtnDelete" style="margin-top: 15px; cursor: pointer">
                      <span class="label">
                        Incluso nell'abbonamento
                        <span @click="goToUnlimited" style="cursor: pointer" class="title uppercase">Unlimited</span>
                      </span>
                      <a
                        class="lnk red"
                        @click="
                          loadingDelete = true
                          removeFromUnlimited(item)
                        "
                        title="Rimuovi dalla sezione Unlimited">
                        <span class="mdi mdi-close-circle"></span>
                        <span class="text">Rimuovi</span>
                      </a>
                    </div>
                    <div
                      @click="goToUnlimited"
                      style="cursor: pointer; display: table; margin-top: 15px"
                      class="element__caption__add"
                      v-if="item.showUnlimitedBtnFake && !item.showUnlimitedBtn && item.showUnlimitedBtnFake && !item.showUnlimitedBtnDelete">
                      <!--TRAVERSI DIFESA PENALE 2014-->
                      <span class="label">
                        Incluso nell'abbonamento
                        <span @click="goToUnlimited" style="cursor: pointer" class="title uppercase">Unlimited</span>
                        <br /><br />
                        <a target="_blank" @click="staticUnlimited()" class="metadata" style="color: #0095d8; display: block; text-align: center; vertical-align: text-bottom"
                          >Scopri di più</a
                        >
                      </span>
                    </div>
                  </div>
                </div>
                <div class="index">
                  <div class="index__actions" v-show="settings.catalogo && settings.catalogo.search && settings.catalogo.search.nlp && settings.catalogo.search.nlp.textOrig">
                    <!-- CTA INDICE con freccia in giù da mostrare quando l'indice è chiuso -->
                    <span title="Indice" class="lnk arrow-right blue" style="cursor: pointer" v-show="!item.indexOpen" @click="toggleChapters(item)">
                      <span class="text">Espandi tutti i risultati</span>
                      <span class="mdi mdi-chevron-down"></span>
                    </span>

                    <!-- CTA INDICE con freccia in su da mostrare quando l'indice è aperto -->
                    <span title="Indice" class="lnk arrow-right blue" style="cursor: pointer" v-show="item.indexOpen" @click="toggleChapters(item)">
                      <span class="text">Restringi tutti i risultati</span>
                      <span class="mdi mdi-chevron-up"></span>
                    </span>
                  </div>
                  <!-- INDEX BOX contiene l'indice da mostrare al click della CTA INDICE -->
                  <!-- <div class="index__box-overflow bg-grey" >
                    <ul class="lnk-list" style="text-align:left; max-width:fit-content !important">
                      <li v-if="item.indexList.length == 0">Caricamento in corso..</li>
                      <li
                        v-for="(summary, i) in item.indexList"
                        :key="i"
                        @click="openViewer(summary)"
                      >
                        <a
                          :title="summary.campo_calcolato.estremo"
                          style="text-overflow:clip !important; text-decoration:underline"
                          v-html="summary.campo_calcolato.estremo"
                        ></a>
                      </li>
                  </ul>-->

                  <div class="other-results__box" v-show="item.indexOpen">
                    <div class="other-results__header"></div>
                    <div class="other-results__items">
                      <div class="other-results__items__item" v-for="chapter in item.chapters" :key="chapter">
                        <v-list-item-content style="text-align: left">
                          <v-list-item-title v-if="chapter._source">
                            <h6
                              :class="[
                                {
                                  'title uppercase': true,
                                  'title-visited': visite(chapter, 'check', true)
                                }
                              ]"
                              v-if="chapter.highlight && chapter.highlight['documento.descrizione']"
                              v-html="chapter.highlight['documento.descrizione'][0]"
                              style="cursor: pointer"
                              @click="
                                visite(chapter, 'set', true)
                                openViewer(chapter)
                              "></h6>
                            <h6
                              :class="[
                                {
                                  'title uppercase': true,
                                  'title-visited': visite(chapter, 'check', true)
                                }
                              ]"
                              v-else
                              v-html="chapter._source.documento.descrizione"
                              style="cursor: pointer"
                              @click="
                                visite(chapter, 'set', true)
                                openViewer(chapter)
                              "></h6>
                          </v-list-item-title>
                          <span class="metadata">
                            Pagine capitolo:
                            {{ chapter._source.documento.pagine }}
                            <abbr v-if="chapter.pdf_partial_view" style="color: black">(Visualizzazione limitata al 10% delle pagine del capitolo)</abbr>
                          </span>
                          <div
                            v-if="chapter.highlight && chapter.highlight['testo.italian_stemmer']"
                            @click="
                              visite(chapter, 'set')
                              openViewer(chapter)
                            ">
                            <span v-for="text in chapter.highlight['testo.italian_stemmer']" :key="text">
                              <a v-html="text" style="text-decoration: underline; color: #00a0e0; font-size: 0.813em"></a>
                            </span>
                          </div>
                        </v-list-item-content>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="results__list__pagination">
              <div class="col50 floatL">
                <span class="results-total">
                  <span class="number">{{ totalResults }}</span>
                  <span>risultati trovati</span>
                </span>
              </div>
              <div class="col50 floatR">
                <div class="pagination-number">
                  <v-pagination v-model="page" v-if="list.length > 0" :length="totalPages" :total-visible="total_visible"></v-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <!-- fine MAIN -->
  </div>
</template>

<script>
  import axios from 'axios'
  import configuration from '../configuration_variables.json'
  import viewer from '../components/Viewer'

  export default {
    props: {
      authorization: {
        type: Object
      },
      overlay: {
        type: Boolean
      },
      settings: {
        type: Object
      }
    },
    components: {
      Viewer: viewer
    },
    data: (vm) => ({
      page: 1,
      totalPages: 1,
      loaded: false,
      dateFrom: null,
      dateTo: new Date().getFullYear(),
      author: '',
      user: null,
      catalogue: null,
      materie: [],
      chapters: [],
      materiaSelected: null,
      totalResults: 0,
      currentAuthor: '',
      paginationKey: 'page',
      searchText: null,
      removenlp: false,
      chapterText: '',
      errorMin: false,
      errorMax: false,
      filtersTypeResearch: ['Tutto il Catalogo', 'I miei volumi', 'Unlimited'],
      rules: {
        min: (value) => vm.check(value, true),
        max: (value) => vm.check(value, false)
      },
      typeResearch: 'Tutto il Catalogo',
      list: [],
      showResAdv: false,
      nlp: null,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      loadingAdd: false,
      showUnlimitedBtn: false,
      showViewer: false,
      showUnlimitedBtnDelete: true,
      showMoreAuthors: false,
      appendIconData: 'arrow_drop_down',
      currentKey: 'viewer',
      loading_viewer: false,
      last2Years: false,
      last5Years: false,
      total_visible: 8,
      placeholder: 'Cerca in tutto il Catalogo GFL',
      currentDateFiltering: '',
      currentOrder: {
        id: 1,
        name: 'Più pertinente',
        sort: {
          name: '_score',
          order: 'desc'
        }
      },
      orders: [
        {
          id: 1,
          name: 'Più pertinente',
          sort: {
            name: '_score',
            order: 'desc'
          }
        },
        {
          id: 2,
          name: 'Più recente',
          sort: {
            name: 'provvedimento.data',
            order: 'desc'
          }
        }
      ]
    }),
    watch: {
      page(val) {
        this.showResAdv = true
        //this.dateFrom = this.settings.catalogo.filters ? this.settings.catalogo.filters.dateFrom : null;
        //this.dateTo = this.settings.catalogo.filters ? this.settings.catalogo.filters.dateTo : null;
        this.research((val - 1) * 8, 8, this.typeResearch)
        window.scrollTo(0, 0)
      },
      menu(val) {
        /*
      @click="changeIcon()"
      @blur="changeIcon()"
      onfocusout="changeIcon()"
      */
        if (val == false) this.appendIconData = 'arrow_drop_down'
        else this.appendIconData = 'arrow_drop_up'
      },
      last2Years(val) {
        if (!val && !this.last5Years) this.dateFrom = null
        else if (!val && this.last5Years) {
          this.dateFrom = new Date().getFullYear() - 5
        } else {
          this.dateFrom = new Date().getFullYear() - 2
        }
        this.dateTo = new Date().getFullYear()
        this.showResAdv = true
        this.currentDateFiltering = this.dateFrom ? ': ' + this.dateFrom + ' - ' + this.dateTo : ''
        //this.research((this.page - 1) * 8, 8, this.typeResearch);
        if (val == true) this.last5Years = false
      },
      last5Years(val) {
        if (!val && !this.last2Years) this.dateFrom = null
        else if (!val && this.last2Years) {
          this.dateFrom = new Date().getFullYear() - 2
        } else {
          this.dateFrom = new Date().getFullYear() - 5
        }
        this.dateTo = new Date().getFullYear()
        /*this.dateFrom = this.settings.catalogo.filters.dateFrom
          ? parseInt(this.settings.catalogo.filters.dateFrom)
          : null;
        this.dateTo = this.settings.catalogo.filters.dateTo
          ? parseInt(this.settings.catalogo.filters.dateTo)
          :  new Date().getFullYear();

         this.currentDateFiltering = this.dateFrom
        ? ": " + this.dateFrom + " - " + this.dateTo
        : "";*/

        this.showResAdv = true
        this.currentDateFiltering = this.dateFrom ? ': ' + this.dateFrom + ' - ' + this.dateTo : ''
        //this.research((this.page - 1) * 8, 8, this.typeResearch);
        if (val == true) this.last2Years = false

        if (this.settings.catalogo.comingBack || this.settings.comingBack) {
          this.dateFrom = this.settings.catalogo.filters.dateFrom ? parseInt(this.settings.catalogo.filters.dateFrom) : null
          this.dateTo = this.settings.catalogo.filters.dateTo ? parseInt(this.settings.catalogo.filters.dateTo) : null

          this.currentDateFiltering = this.dateFrom ? ': ' + this.dateFrom + ' - ' + this.dateTo : ''

          this.settings.catalogo.comingBack = false
          this.settings.comingBack = false
        }
      }
    },
    mounted() {
      //this.$gtm.trackView("pageview", this.$route.path);
      //console.log("res");
      if (this.authorization.jwt) {
        this.user = this.authorization
      }
      this.checkRules()
      console.log('result')
      if (this.settings.catalogo && this.settings.catalogo.search) {
        localStorage.materie ? (this.materie = JSON.parse(localStorage.materie)) : null
        this.materiaSelected = this.settings.catalogo.fromDetail ? null : this.settings.catalogo.materiaSelected ? [this.settings.catalogo.materiaSelected] : null
        this.searchText = this.settings.catalogo.search.nlp ? this.settings.catalogo.search.nlp.textOrig : ''
        this.list = this.settings.catalogo.search.es.list ? this.settings.catalogo.search.es.list : []
        this.removenlp = this.settings.removenlp === true ? true : false
        this.typeResearch = this.settings.curentFilter ? this.settings.curentFilter : 'Tutto il Catalogo'
        if (this.settings.catalogo.search.nlp && this.settings.catalogo.search.nlp.entities && this.settings.catalogo.search.nlp.entities.length > 0) {
          var autore = this.settings.catalogo.search.nlp.entities.find((x) => x.entity.toString() == 'autore')
          if (autore) {
            this.showSearchByAuthor = true
            this.currentAuthor = autore.value
          }
        } else {
          this.showSearchByAuthor = false
        }
        if (this.settings.catalogo.filters) {
          if (this.settings.comingBack) {
            this.last2Years = this.settings.catalogo.filters.last2Years ? this.settings.catalogo.filters.last2Years : null
            this.last5Years = this.settings.catalogo.filters.last5Years ? this.settings.catalogo.filters.last5Years : null
            //this.settings.comingBack = false;
          }

          this.dateFrom = this.settings.catalogo.filters.dateFrom ? parseInt(this.settings.catalogo.filters.dateFrom) : null
          this.dateTo = this.settings.catalogo.filters.dateTo ? parseInt(this.settings.catalogo.filters.dateTo) : null
          this.showResAdv = true
          this.currentDateFiltering = this.dateFrom ? ': ' + this.dateFrom + ' - ' + this.dateTo : ''

          this.author = this.settings.catalogo.filters.author ? this.settings.catalogo.filters.author : null
          this.typeResearch = this.settings.catalogo.filters.typeResearch ? this.settings.catalogo.filters.typeResearch : 'Tutto il Catalogo'
          this.page = this.settings.catalogo.filters.currentPage ? this.settings.catalogo.filters.currentPage : 1
        }

        if (this.typeResearch == 'Tutto il Catalogo') this.placeholder = 'Cerca in tutto il Catalogo GFL'
        if (this.typeResearch == 'I miei volumi') this.placeholder = 'Cerca ne I miei Volumi'
        if (this.typeResearch == 'Unlimited') this.placeholder = 'Cerca in Unlimited'

        this.list.forEach((element) => {
          element.indexOpen = false
          element.indexList = []
          if (this.checkRulesNotUnlimited()) {
            if (element._source.provvedimento.isUnlimited) {
              element.showUnlimitedBtnFake = true
            } else {
              element.showUnlimitedBtnFake = false
            }
          }
          if (this.verifyUserConditions(element, 'abbonato')) {
            element.showMyVolumesBtn = true
            if (element._source.provvedimento.isUnlimited) {
              element.showUnlimitedBtnFake = true
            }
          }
          if (this.verifyUserConditions(element, 'unlimited')) {
            element.showUnlimitedBtn = true
          }
          if (this.verifyUserConditions(element, 'unlimitedDelete')) {
            element.showUnlimitedBtnDelete = true
          }
        })
        this.totalResults = this.settings.catalogo.search.es.total ? this.settings.catalogo.search.es.total : 0
        this.nlp = this.settings.catalogo.search.nlp
        this.totalPages = parseInt(Math.round(this.totalResults / 8)) >= 1 ? parseInt(Math.ceil(this.totalResults / 8)) : 1
        this.chapterText = this.settings.catalogo.search.nlp ? this.settings.catalogo.search.nlp.textOrig : null
        this.catalogue = true

        if (this.$route.query.hadVolumes || (this.settings.catalogo && this.settings.catalogo.hadVolumes)) {
          this.settings.catalogo.hadVolumes = true
          this.total_visible = this.settings.catalogo.search.es.list.length
          this.totalPages = 1
          this.totalResults = this.total_visible
          this.settings.catalogo.search.es.total = this.totalResults
          this.page = 1
        }

        this.$forceUpdate()
      } else {
        if (this.settings.configuration) {
          var header = {
            Authorization: localStorage.token_biblio_vol_jwt
          }

          if (localStorage.materie) {
            //controllo la data di scadenza per il refresh, settata a 20 min
            if (localStorage.dateSavedMaterie) {
              var now = Date.parse(new Date()) / 1000
              var exp = parseInt(localStorage.dateSavedMaterie) + parseInt(this.settings.configuration.timeoutReloadMaterie)
              if (now >= exp) {
                this.loadMaterie(header)
              } else {
                this.materie = JSON.parse(localStorage.materie)
              }
            } else {
              localStorage.materie = []
              this.loadMaterie(header)
            }
          } else {
            this.loadMaterie(header)
          }
          if (this.$route.query.more == 'true' || this.$route.query.more == true) {
            this.searchText = ''
            this.currentOrder = {
              id: 2,
              name: 'Più recente',
              sort: {
                name: 'provvedimento.data',
                order: 'desc'
              }
            }
            if (this.settings.comingBack) {
              this.settings.comingBack = false
              this.page = this.settings.catalogo.currentPage
            } else {
              this.page = 2
            }
            //this.research((this.page - 1) * 8, 8, "Tutto il Catalogo");
            this.catalogue = true
          }
        }
      }
    },
    created() {},
    methods: {
      async REQUEST(call, header, method, type, body) {
        var self = this
        this.loading = true
        if (method == 'POST') {
          await axios
            .post(call, body, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'list':
                  self.catalogue = response.data.list
                  break
                case 'chapters':
                  self.chapters = response.data.list

                  break
                case 'unlimited':
                  self.loadingAdd = false
                  self.list.forEach((element) => {
                    if (body.cod_vol == element._source.provvedimento.codVol) {
                      element.showUnlimitedBtnDelete = true
                      element.showUnlimitedBtn = false
                    }
                  })
                  self.$forceUpdate()
                  break
                case 'search':
                  //
                  if (self.searchText && body.filters.length == 0) {
                    self.$gtm.trackEvent({
                      event: 'sezione',
                      azione: 'ricerca_semplice',
                      descrizione: self.searchText,
                      proprieta: self.page,
                      utente: self.authorization && self.authorization.profile && self.authorization.profile.userId ? self.authorization.profile.userId : null,
                      crmid: self.authorization && self.authorization.profile && self.authorization.profile.crmId ? self.authorization.profile.crmId : null,
                      stato: ''
                    })
                  }
                  if (!self.$route.query.fromButton && body.filters.length) {
                    self.$gtm.trackEvent({
                      event: 'sezione',
                      azione: 'ricerca_avanzata',
                      descrizione: self.searchText,
                      proprieta: body.filters.concat([{ page: self.page }]),
                      utente: self.authorization && self.authorization.profile && self.authorization.profile.userId ? self.authorization.profile.userId : null,
                      crmid: self.authorization && self.authorization.profile && self.authorization.profile.crmId ? self.authorization.profile.crmId : null,
                      stato: ''
                    })
                  }
                  if (!self.searchText && body.filters.length == 0) {
                    self.$gtm.trackEvent({
                      event: 'sezione',
                      azione: 'open',
                      descrizione: 'catalogo',
                      proprieta: self.page,
                      utente: self.authorization && self.authorization.profile && self.authorization.profile.userId ? self.authorization.profile.userId : null,
                      crmid: self.authorization && self.authorization.profile && self.authorization.profile.crmId ? self.authorization.profile.crmId : null,
                      stato: ''
                    })
                  }

                  //
                  var wasVolumes = false
                  if (!self.searchText && self.settings.catalogo && self.settings.catalogo.hadVolumes) {
                    wasVolumes = true
                  }
                  self.list = response.data.es.list
                  self.list ? self.list : (self.list = [])
                  self.list.forEach((element) => {
                    element.indexOpen = false
                    element.indexList = []
                    if (self.checkRulesNotUnlimited()) {
                      if (element._source.provvedimento.isUnlimited) {
                        element.showUnlimitedBtnFake = true
                      } else {
                        element.showUnlimitedBtnFake = false
                      }
                    }
                    if (self.verifyUserConditions(element, 'abbonato')) {
                      element.showMyVolumesBtn = true
                    }
                    if (self.verifyUserConditions(element, 'unlimited')) {
                      element.showUnlimitedBtn = true
                      element.showUnlimitedBtnFake = false
                    }
                    if (self.verifyUserConditions(element, 'unlimitedDelete')) {
                      element.showUnlimitedBtnDelete = true
                      element.showUnlimitedBtnFake = false
                    }
                  })
                  self.nlp = response.data.nlp
                  self.totalResults = response.data.es.total ? response.data.es.total : 0
                  self.totalPages = parseInt(Math.round(self.totalResults / 8)) >= 1 ? parseInt(Math.ceil(self.totalResults / 8)) : 1

                  if ((self.$route.query.more == 'true' || self.$route.query.more == true) && (self.$route.query.fromButton == 'true' || self.$route.query.fromButton == true)) {
                    self.totalPages = 3 // configuare page x 3 costante
                  }
                  self.chapterText = self.nlp ? response.data.nlp.textOrig : null
                  self.loaded = true
                  self.settings.catalogo = {
                    search: response.data,
                    materiaSelected: self.materiaSelected,
                    materie: self.materie
                  }
                  if (response.data.nlp && response.data.nlp.entities.length > 0) {
                    var autore = response.data.nlp.entities.find((x) => x.entity.toString() == 'autore')
                    if (autore) {
                      self.showSearchByAuthor = true
                      self.currentAuthor = autore.value
                    }
                  } else {
                    self.showSearchByAuthor = false
                    self.settings.catalogo.search.nlp = {}
                    self.settings.catalogo.search.nlp.textOrig = self.searchText
                  }
                  if (!self.searchText && wasVolumes) {
                    self.settings.catalogo.hadVolumes = true
                  }
                  self.showResAdv = false
                  self.$forceUpdate()
                  self.refreshComponent()
              }
            })
            .catch(function (error) {
              //localStorage.token_biblio_vol = null;
              //localStorage.token_biblio_vol_jwt = self.settings.free.jwt;
              //self.token = self.settings.free.jwt;
              //self.settings.freeToken = true;
            })
        } else if (method == 'GET') {
          await axios
            .get(call, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'materie':
                  self.materie = response.data.materie
                  self.materie.unshift({
                    nome_interesse: 'Tutto il catalogo'.toUpperCase()
                  })
                  self.settings.catalogo.materie = self.materie
                  localStorage.materie = JSON.stringify(self.materie)
                  localStorage.dateSavedMaterie = Date.parse(new Date()) / 1000
                  break
                case 'recoverSummaryApart':
                  var cod = call.split('sommario/')[1]
                  var elements = response.data.documenti
                  self.list.forEach((element) => {
                    if (element._source.provvedimento.idDocMaster == parseInt(cod)) {
                      element.indexList = elements
                      self.$forceUpdate()
                    }
                  })
                  break
                case 'detail':
                  var cod = call.split('codice_volume/')[1]
                  var item = response.data
                  if (!item.documenti || self.settings.freeToken) {
                    //non sono loggato, recupero il sommario a parte
                    //nascondo certi elementi
                    self.recoverSummary(item.provvedimento.id_doc_master)
                  } else {
                    self.list.forEach((element) => {
                      if (element._source.provvedimento.codVol == cod) {
                        element.indexList = item.documenti
                        self.$forceUpdate()
                      }
                    })
                  }
                  break
              }
            })
            .catch(function (error) {
              self.loaded = true
            })
        } else {
          await axios
            .delete(call, { data: body, headers: header })
            .then(function (response) {
              switch (type) {
                case 'unlimited':
                  self.loadingDelete = false
                  self.list.forEach((element) => {
                    if (body.cod_vol == element._source.provvedimento.codVol) {
                      element.showUnlimitedBtnDelete = false
                      element.showUnlimitedBtn = true
                    }
                  })
                  self.$forceUpdate()
                  break
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      },
      goToUnlimited() {
        this.$router.push({ path: '/unlimited' })
      },
      createSessionTimestamp() {
        var d = new Date()
        var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()
        return session
      },
      goToShop(item) {
        window.open('https://shop.giuffre.it/product.php?cod=' + item._source.provvedimento.codVol + '', '_blank')
      },
      async getChapters(item) {
        let header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }
        let body = {
          text: this.searchText,
          sort: {
            name: '_score',
            order: 'desc'
          },
          filters: [
            {
              name: 'codVol',
              value: item._source.provvedimento.codVol
            }
          ]
        }
        //BVA-58
        let call = configuration.apiURL + '/search/volumi/capitoli/0/100'
        return await axios.post(call, body, { headers: header }).then((response) => {
          return response.data.list
        })
      },
      openViewer(item) {
        this.loading_viewer = true
        var url = this.settings.pdfviewer ? this.settings.pdfviewer + 'newViewer.html' : this.settings.pdfviewer_express
        if (this.hasEntities) {
          url += '?token=' + item.v_token
        } else {
          if (true) {
            url += '?search=' + this.searchText + '&token=' + item.v_token
          } else {
            url += '?token=' + item.v_token
          }
        }
        //window.open(url, "_blank");
        this.settings.viewerUrl = url
        this.showViewer = true
        window.scroll(0, 0)
        this.refreshComponent()
        setTimeout(() => {
          this.loading_viewer = false
        }, 3000)
      },
      rndStr(len) {
        let text = ' '
        let chars = 'abcdefghijklmnopqrstuvwxyz'
        for (let i = 0; i < len; i++) {
          text += chars.charAt(Math.floor(Math.random() * chars.length))
        }
        return text
      },
      showMoreAuth(item) {
        item.showMoreAuthors = true
        this.$forceUpdate()
      },
      refreshComponent() {
        this.currentKey = this.rndStr(8)
        this.paginationKey = this.rndStr(8)
        this.$forceUpdate()
      },
      checkEstrattoRule() {
        if (this.user) {
          //se è loggato
          //verifico che non sia unlimited
          var unl = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == 'bvunlimited')
          var role37 = this.user.profile.roles.find((x) => x.pos.toString().toLowerCase() == '37')
          var role36 = this.user.profile.roles.find((x) => x.pos.toString().toLowerCase() == '36')
          if (unl) return 'unlimited'
          //c'era in or anche !role36
          else {
            if (role37 || role36) return 'other'
            return 'none'
          }
        } else {
          return 'none'
        }
      },
      check(val, from) {
        //value <= vm.dateTo || 'Errore nelle date'
        if (from) {
          if (parseInt(val) > parseInt(this.dateTo)) {
            return 'Errore nelle date.'
          }
        } else {
          if (val != '' && parseInt(val) < parseInt(this.dateFrom)) return 'Errore nelle date.'
        }
      },
      toggleIndex(item) {
        item.indexOpen = !item.indexOpen
        if (item.indexOpen && item.indexList.length == 0) {
          //richiamo l'indice
          this.recoverIndex(item._source.provvedimento.codVol)
        } else {
        }
        this.$forceUpdate()
      },
      async toggleChapters(item) {
        item.indexOpen = !item.indexOpen
        if (item.indexOpen && item.indexList.length == 0) {
          item.chapters = await this.getChapters(item)
        }
        this.$forceUpdate()
      },
      removeNlp() {
        this.removenlp = true
        this.settings.removenlp = true
        this.$route.query.more = null
        if (this.page == 1) this.research((1 - 1) * 8, 8, this.typeResearch, true)
        else this.page = 1
        this.showSearchByAuthor = false
        //this.$forceUpdate();
      },
      removeFromUnlimited(item) {
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }
        var body = {
          cod_vol: item._source.provvedimento.codVol
        }
        this.REQUEST(configuration.apiURL + '/unlimited', header, 'DELETE', 'unlimited', body)
      },
      addToUnlimited(item) {
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }
        var body = {
          cod_vol: item._source.provvedimento.codVol
        }
        this.REQUEST(configuration.apiURL + '/unlimited', header, 'POST', 'unlimited', body)
      },
      recoverIndex(codice) {
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }
        this.REQUEST(configuration.apiURL + '/volume/info/codice_volume/' + codice, header, 'GET', 'detail', null)
      },
      visite(item, type, summary) {
        if (type == 'set') {
          var id = summary ? item._id : item._source.provvedimento.codVol
          var arr = []
          if (!localStorage.highlight_id) arr = []
          else arr = JSON.parse(localStorage.highlight_id)

          arr.push(id.toString())
          localStorage.highlight_id = JSON.stringify(arr)
        } else if (type == 'check' && localStorage.highlight_id) {
          var find = ''
          if (!summary) {
            find = JSON.parse(localStorage.highlight_id).find((x) => x == item._source.provvedimento.codVol.toString())
          } else {
            find = JSON.parse(localStorage.highlight_id).find((x) => x == item._id.toString())
          }
          if (find) return true
          else return false
        } else return false
      },
      recoverSummary(id) {
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }
        this.REQUEST(configuration.apiURL + '/volume/sommario/' + id, header, 'GET', 'recoverSummaryApart', null)
      },
      loadMaterie(header) {
        this.REQUEST(this.settings.configuration.apiURL + '/materie', header, 'GET', 'materie', null)
      },
      setMateria() {
        this.page = 1
        this.showResAdv = true
        this.research((this.page - 1) * 8, 8, this.typeResearch)
      },
      setOrder() {
        this.research((this.page - 1) * 8, 8, this.typeResearch)
      },
      verifyUserConditions(item, type) {
        //usa solo i flag show oppure solo questa condizione.. e poi verifica che funzioni lo stesso giro pure dentro details.vue
        //agg ad unlimited: se isUnlimited è true e utente.unlimited è false
        //remove from unlimited: se isUnlimited è true e utente.unlimited è true
        if (type == 'unlimited') {
          if (this.settings.freeToken) {
            return false
          } else {
            if (this.checkRulesUnlimited()) {
              if (item._source.provvedimento.utente.unlimited == false && item._source.provvedimento.isUnlimited) {
                return true
                //return true;
              } else return false
            } else return false
          }
        } else if (type == 'abbonato') {
          if (this.settings.freeToken) {
            return false
          } else {
            if (this.checkRules()) {
              if (item._source.provvedimento.utente.volume) {
                return true
              } else return false
            } else return false
          }
        } else if (type == 'unlimitedDelete') {
          //
          if (this.settings.freeToken) {
            return false
          } else {
            if (this.checkRulesUnlimited()) {
              if (item._source.provvedimento.utente.unlimited == true && item._source.provvedimento.isUnlimited) {
                return true
                //return true;
              } else return false
            } else return false
          }
        }
      },
      detail(item) {
        if (this.settings.catalogo && this.settings.catalogo.filters) {
          /*this.settings.catalogo = {};
        this.settings.catalogo.filters = {};*/
        } else {
          if (this.settings.catalogo) {
          } else this.settings.catalogo = {}
          this.settings.catalogo.filters = {}
        }
        if (this.dateFrom) {
          this.settings.catalogo.filters.dateFrom = this.dateFrom
        }
        if (this.dateTo) {
          this.settings.catalogo.filters.dateTo = this.dateTo
        }
        if (this.materiaSelected) {
          this.settings.catalogo.filters.dateTo = this.dateTo
        }
        if (this.last2Years) {
          this.settings.catalogo.filters.last2Years = true
          this.settings.catalogo.filters.last5Years = false
        } else if (this.last5Years) {
          this.settings.catalogo.filters.last5Years = true
          this.settings.catalogo.filters.last2Years = false
        }
        this.settings.catalogo.filters.currentPage = this.page
        if (this.nlp && this.nlp.text == '' && this.nlp.entities.length > 0) {
          this.$router.push({
            path: '/details',
            query: {
              cod_vol: item._source.provvedimento.codVol,
              fromSearch: this.searchText,
              entities: true,
              from: this.typeResearch,
              fromResults: true
            }
          })
        } else {
          this.$router.push({
            path: '/details',
            query: {
              cod_vol: item._source.provvedimento.codVol,
              fromSearch: this.searchText,
              entities: false,
              from: this.typeResearch,
              fromResults: true
            }
          })
        }
      },
      checkRules() {
        var ok = false
        if (this.user) {
          var role37 = this.user.profile.roles.find((x) => x.pos == 37)
          if (role37) {
            this.filtersTypeResearch = ['Tutto il Catalogo', 'I miei volumi', 'Unlimited']
            ok = true
          } else this.filtersTypeResearch = ['Tutto il Catalogo', 'Unlimited']
        } else {
          this.filtersTypeResearch = ['Tutto il Catalogo', 'Unlimited']
        }
        return ok
      },
      checkRulesNotUnlimited() {
        if (this.user) {
          var role37 = this.user.profile.roles.find((x) => x.pos.toString().toLowerCase() == '37')
          var role36 = this.user.profile.roles.find((x) => x.pos.toString().toLowerCase() == '36')
          var unl = this.user.profile.roles.find((x) => x.pos.toString().toLowerCase() == 'bvunlimited')
          if (role37 && !unl) return true
          //c'era in or anche !role36
          else return false
        } else {
          return true
        }
      },
      staticUnlimited() {
        //this.settings.tempStaticUnlimited = true;
        this.$router.push({ path: '/unlimited' })
      },
      checkRulesUnlimited() {
        if (this.user) {
          var roleUnl = this.user.profile.roles.find((x) => x.pos.toString().toLowerCase() == 'bvunlimited') // || x.pos.toString() == "36"
          if (roleUnl) return true
          else return false
        } else return false
      },
      keyPress(e) {
        if (e.keyCode === 13) {
          this.$route.query.more = null
          this.page = 1
          this.removenlp = false
          this.settings.removenlp = false
          this.materiaSelected = null
          this.showResAdv = false
          this.currentDateFiltering = ''
          this.dateTo = null
          this.dateFrom = null
          this.last2Years = false
          this.last5Years = false
          this.research((this.page - 1) * 8, 8, 'Tutto il Catalogo', true)
        }
      },
      filtering() {
        this.page = 1
        this.settings.curentFilter = this.typeResearch
        this.showResAdv = true
        if (this.typeResearch == 'Tutto il Catalogo') this.placeholder = 'Cerca in tutto il Catalogo GFL'
        if (this.typeResearch == 'I miei volumi') this.placeholder = 'Cerca ne I miei Volumi'
        if (this.typeResearch == 'Unlimited') this.placeholder = 'Cerca in Unlimited'
        this.research((this.page - 1) * 8, 8, this.typeResearch)
      },
      checkConditionVolume(item){
        return (this.checkEstrattoRule() != 'unlimited' && !item.showMyVolumesBtn) || (item._source.provvedimento.utente && item._source.provvedimento.utente.volume == false && !item._source.provvedimento.isUnlimited)
      },
      getImage(item) {
        //BVA-93
        let prefix = this.settings.copertina + '/pdf/'
        let uri = item.provvedimento.idDocMaster.toString().substring(item.provvedimento.idDocMaster.toString().length - 3, item.provvedimento.idDocMaster.toString().length)
        let fix = '/' + item.provvedimento.campiCalcolati.cover_url
        let final = prefix + uri + fix.replace('//', '/') + '?width=217&fit=outside'
        return final.replace(/([^:]\/)\/+/g, '$1')
      },
      research(from, to, type, newSearch) {
        var body = {}
        var stop = false
        if (newSearch || this.removenlp) {
          this.typeResearch = type
          this.currentOrder = {
            id: 1,
            name: 'Più pertinente',
            sort: {
              name: '_score',
              order: 'desc'
            }
          }
        }
        if ((type == 'I miei volumi' || type == 'Unlimited') && !this.searchText) {
          this.typeResearch = type
          this.currentOrder = {
            id: 1,
            name: 'Più recente',
            sort: {
              name: 'provvedimento.data',
              order: 'desc'
            }
          }
        }
        if (true) {
          if (!this.searchText && !this.$route.query.fromButton) {
            //PBV-277
            this.currentOrder = {
              id: 1,
              name: 'Più recente',
              sort: {
                name: 'provvedimento.data',
                order: 'desc'
              }
            }
          }
          if (this.$route.query.fromButton) {
            this.$gtm.trackEvent({
              event: 'sezione',
              azione: 'open',
              descrizione: 'catalogo',
              proprieta: this.page,
              utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
              crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
              stato: ''
            })
            this.$route.query.fromButton = false
          }
          if (
            this.materiaSelected &&
            this.materiaSelected.length > 0 &&
            this.materiaSelected.filter((x) => x.nome_interesse && x.nome_interesse.toUpperCase() != 'TUTTO IL CATALOGO').length == this.materiaSelected.length
          ) {
            var all = []
            this.materiaSelected.forEach((element) => {
              all.push(element.interesse_id)
            })
            body = {
              filters: [{ name: 'materia', value: all }],
              sort: this.currentOrder.sort
            }
          } else {
            body = {
              filters: [],
              sort: this.currentOrder.sort
            }
          }
          if (this.searchText || this.$route.query.more == 'true' || this.$route.query.more == true) {
            if (this.searchText) body.text = this.searchText
          }
          if (type && type == 'Tutto il Catalogo') {
          }
          if (type && type == 'I miei volumi') {
            body.filters.push({ name: 'codiciCliente', value: true })
          }
          if (type && type == 'Unlimited') {
            if (this.settings.catalogo && this.settings.catalogo.filters && this.settings.catalogo.filters.fromUnlimited && this.settings.catalogo.filters.currentPage > 1) {
              body.filters.push({ name: 'codiciUnlimited', value: true })
            } else body.filters.push({ name: 'volumiUnlimited', value: true })
          }
          if (this.showResAdv) {
            var filterDate = {}
            //verifiche
            if (parseInt(this.dateFrom) <= parseInt(this.dateTo) && parseInt(this.dateFrom) > 0 && parseInt(this.dateTo) > 0) {
              if (this.dateFrom != null || this.dateTo != null) {
                filterDate.name = 'range'
                var values = {
                  from: this.dateFrom ? this.dateFrom.toString() + '-01-01T00:00:00' : '1930-01-01T00:00:00',
                  to: this.dateTo ? this.dateTo.toString() + '-12-31T00:00:00' : '2100-01-01T00:00:00'
                }
                filterDate.value = values
                body.filters.push(filterDate)
              }
            } else {
              if (this.dateFrom && this.dateTo) stop = true
            }
            var filterAuthor = {}
            if (this.author != '' && this.author != null && !this.removeNlp) {
              filterAuthor.name = 'autore'
              var values = {
                cognome: this.author
              }
              filterAuthor.value = values
              body.filters.push(filterAuthor)
            }
          }
          if (
            (this.$route.query.more == 'true' || this.$route.query.more == true) &&
            !this.$route.query.materia &&
            (this.$route.query.fromButton == 'true' || this.$route.query.fromButton == true) &&
            !this.last2Years &&
            !this.last5Years &&
            !this.dateFrom
          ) {
            //filtri
            var filterDate = {}
            filterDate.name = 'range'
            var d = new Date()
            d.setMonth(d.getMonth() + 1 - 4) //limit 3 x size

            /*from:
              d.getFullYear().toString() +
              "-" +
              ((d.getMonth() < 10) ? '0' + d.getMonth().toString() : d.getMonth().toString()) +
              "-" +
              d.getDate().toString() +
              "T00:00:00",
          */

            var values = {
              from: d.getFullYear().toString() + '-01-01' + 'T00:00:00',
              to:
                new Date().getFullYear().toString() +
                '-' +
                (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString()) +
                '-' +
                (new Date().getDate() + 1 < 10 ? '0' + (new Date().getDate() + 1).toString() : (new Date().getDate() + 1).toString()) +
                'T00:00:00'
            }
            filterDate.value = values
            body.filters.push(filterDate)
          }

          if (!this.searchText && this.settings.catalogo && this.settings.catalogo.hadVolumes && this.settings.urlVolumes) {
            body.filters.push({
              name: 'codVols',
              value: this.settings.urlVolumes
            })
            delete body.text
            from = 0
            to = this.settings.urlVolumes.length
            body.sort = {
              name: 'provvedimento.data',
              order: 'desc'
            }
          }
          if (!stop) {
            var header = {
              Authorization: localStorage.token_biblio_vol_jwt
            }
            var nlp = this.removenlp ? '?nlp=false' : '?nlp=true'
            nlp = this.settings.catalogo && this.settings.catalogo.hadVolumes ? '' : nlp
            this.REQUEST(this.settings.configuration.apiURL + '/search/volumi/' + from + '/' + to + nlp, header, 'POST', 'search', body)
          }
        }
      }
    }
  }
</script>
<style>
  .v-menu__content.theme--light.menuable__content__active {
    top: 180px !important;
  }

  .v-menu__content {
    box-shadow: 0 3px 12px #cfcfcf !important;
  }

  .v-select__selections input,
  .srch input {
    background: transparent !important;
    border: none;
    padding-left: 0px !important;
  }
  .element__caption__add {
    position: relative !important;
  }
  .floatR .slct {
    border: solid 1px #e6e6e6;
  }

  .inp .v-input__slot {
    width: 100px !important;
  }
  .srch .v-input__slot {
    height: 40px !important;
    font-size: 11pt;
  }

  .v-menu__content.theme--light.menuable__content__active {
    /*min-width: 350px !important;
  width: 350px !important;*/
  }

  .element__caption__add {
    width: 240px !important;
  }

  .srch input {
    /*cursor: pointer;*/
  }
  .title-visited {
    color: #7608af !important;
  }
  em {
    /*background: gold !important;
  padding: 2px !important;*/
    color: #ba0000;
    font-size: inherit !important;
    font-weight: normal !important;
    font-style: initial !important;
  }

  @media (max-width: 767px) {
    .search__caption__copy__searchbox .srch,
    .search__caption__copy__searchbox .srch {
      margin-bottom: 0.7em;
      margin-left: 0px !important;
      width: 100% !important;
      border: none !important;
    }
    .srch {
      margin-top: 5px;
    }
    .floatR .slct {
      border: none;
    }
  }
</style>
